import React from "react"
import styled, { useTheme } from "styled-components"
import Text from "components/common/Text"
import Space from "components/common/Space"
import SocialLinks from "components/common/SocialLinks"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { motion } from "framer-motion"
import ConversationTopics from "./ConversationTopics"
import LinkText from "components/common/LinkText"

export const query = graphql`
  query {
    file(relativePath: { eq: "profile_picture.png" }) {
      childImageSharp {
        fluid(
          maxWidth: 600
          maxHeight: 600
          fit: COVER
          quality: 100
          cropFocus: NORTH
          duotone: { highlight: "#22fcd0", shadow: "#212231" }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const TextContainer = styled.div`
  max-width: 90vw;
  margin: 2rem 2rem;
  line-height: 2;
`

const ImageContainer = styled.div`
  min-width: 320px;
  width: 30vw;
  margin: 2rem 4rem;
  margin-bottom: 10rem;
  margin-top: 10vh;
`

const Image = styled(Img)`
  box-shadow: 0px 0px 40px 2px rgba(0, 0, 0, 0.4);
  border-radius: 50%;
`

const IntroContainer = styled(motion.div)`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

const Line = styled.div`
  border-top: ${props => `4px solid ${props.theme.colors.accent.primary[500]}`};
  max-width: 700px;
  margin-bottom: 4px;
`

const Intro = () => {
  const data = useStaticQuery(query)
  const theme = useTheme()
  const accentColor = theme.colors.accent.primary[500]

  return (
    <IntroContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <ImageContainer>
        <Image
          fluid={data.file.childImageSharp.fluid}
          alt="profile picture"
          fadeIn
        />
        <Space margin="2rem 0 0 0">
          <div>
            <SocialLinks size="30px" />
          </div>
        </Space>
      </ImageContainer>
      <TextContainer>
        <Line />
        <Text size="large">{"Hello."}</Text>
        <Text>
          {"I'm"}
          <Text display="inline" weight="bold" color={accentColor}>
            {" Chaitanya"}
          </Text>
          <Text display="inline" weight="normal">
            {"."}
          </Text>
        </Text>
        <Text>{"I ❤️ tech. No, not just coding."}</Text>
        <Text>
          {"I've been obsessed with tech ever since I first played "}
          <LinkText
            href="https://en.wikipedia.org/wiki/Disney%27s_Hercules_(video_game)"
            color={theme.colors.text.primary[400]}
            textDecoration="underline"
          >
            {"Hercules"}
          </LinkText>
          <Text display="inline">{" in 2001."}</Text>
        </Text>
        <Space margin="2rem 0 0 0">
          <Text size="large">{"Right now,"}</Text>
        </Space>
        <Text>{" I work as a Lead Frontend Engineer at Razorpay."}</Text>
        <ConversationTopics />
      </TextContainer>
    </IntroContainer>
  )
}

export default Intro
