import { graphql, useStaticQuery } from "gatsby"

const CAREER_QUERY = graphql`
  query {
    result: allMdx(
      filter: { fileAbsolutePath: { regex: "/career/i" } }
      sort: { fields: frontmatter___position, order: DESC }
    ) {
      career: nodes {
        company: frontmatter {
          name
          shortInfo
        }
      }
    }
  }
`

const useCareer = () => {
  const {
    result: { career },
  } = useStaticQuery(CAREER_QUERY)
  return career
}

export default useCareer
