import { graphql, useStaticQuery } from "gatsby"

const CONVERSATION_TOPICS_QUERY = graphql`
  query {
    result: allMdx(
      filter: { fileAbsolutePath: { regex: "/conversation-topics/i" } }
    ) {
      nodes {
        frontmatter {
          topics
        }
      }
    }
  }
`

const useTopics = () => {
  const {
    result: { nodes },
  } = useStaticQuery(CONVERSATION_TOPICS_QUERY)
  return nodes[0].frontmatter.topics
}

export default useTopics
