import React from "react"
import styled from "styled-components"
import useCareer from "../../hooks/useCareer"
import Text from "../common/Text"
import CareerItem from "./CareerItem"

const Container = styled.div`
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  margin-top: 20vh;
  margin-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
`

const Career = () => {
  const career = useCareer()

  return career ? (
    <Container>
      <Text size="xlarge">I've helped build products for,</Text>
      {career.map(({ company }, index) => (
        <CareerItem
          key={index}
          company={company.name}
          shortInfo={company.shortInfo}
        />
      ))}
    </Container>
  ) : null
}

export default Career
