import React, { useCallback, useEffect, useState } from "react"
import useTopics from "hooks/useTopics"
import Text from "components/common/Text"
import Space from "components/common/Space"
import styled, { useTheme } from "styled-components"
import { motion } from "framer-motion"
import { Event, track } from "../../analytics"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;
  align-items: center;
`

const TopicText = styled(Text)`
  padding: 0px;
  background-color: transparent;
  cursor: pointer;
  min-width: 200px;
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0px;
  &:focus {
    outline: none;
  }
`

const TopicTextContainer = styled(motion.div)`
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  min-width: 50%;
`

const ConversationTopics = () => {
  const topics = useTopics()
  const theme = useTheme()
  const [currentTopicIndex, setCurrentTopicIndex] = useState(
    Math.floor(Math.random() * topics.length)
  )

  const getRandomTopicIndex = useCallback(() => {
    const newTopicIndex = Math.floor(Math.random() * topics.length)
    if (newTopicIndex === currentTopicIndex) {
      getRandomTopicIndex({ currentTopicIndex, topics })
    } else {
      setCurrentTopicIndex(newTopicIndex)
    }
  }, [setCurrentTopicIndex, topics, currentTopicIndex])

  useEffect(() => {
    const interval = setInterval(() => {
      getRandomTopicIndex()
    }, 3000)
    return () => {
      clearInterval(interval)
    }
  }, [currentTopicIndex, getRandomTopicIndex])

  return (
    <Container>
      <Space padding="0 4px 0 0">
        <Text size="medium">{"Talk to me about"}</Text>
      </Space>
      <TopicTextContainer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.8, ease: "easeInOut" }}
        onClick={() => {
          track(Event.conversation_topic_clicked)
          getRandomTopicIndex()
        }}
        key={currentTopicIndex}
      >
        <TopicText
          size="medium"
          weight="normal"
          color={theme.colors.accent.primary[500]}
          as="button"
        >
          {topics[currentTopicIndex]}
        </TopicText>
      </TopicTextContainer>
    </Container>
  )
}

export default ConversationTopics
