import React, { useEffect } from "react"
import Intro from "components/home/Intro"
import Career from "components/home/Career"
import Skills from "components/home/Skills"
import { navigate } from "gatsby"

export default function Home() {
  useEffect(() => {
    navigate("/")
  }, [])
  return (
    <React.Fragment>
      <Intro />
      <Career />
      <Skills />
    </React.Fragment>
  )
}
