import React from "react"
import styled, { useTheme } from "styled-components"
import Text from "../common/Text"

const Container = styled.div`
  align-items: flex-end;
  margin-top: 40px;
`

const Company = styled(Text)`
  margin-bottom: 10px;
  letter-spacing: 1.5px;
`

const ShortInfo = styled(Text)`
  max-width: 800px;
  line-height: 1.5;
`

const CareerItem = ({ company, shortInfo }) => {
  const theme = useTheme()
  return (
    <Container>
      <Company
        size="medium"
        weight="bold"
        color={theme.colors.accent.primary[500]}
      >
        {company}
      </Company>
      <ShortInfo weight="light">{shortInfo}</ShortInfo>
    </Container>
  )
}

export default CareerItem
